import React from 'react';

export const loadable = {
  Login: React.lazy(() => import('../pages/Login/Login')),
  RestorePWD: React.lazy(() => import('../pages/restorePwd/RestorePWD')),
  NewPWD: React.lazy(() => import('../pages/newPwd/NewPWD')),
  Universities: React.lazy(() => import('../pages/Universities/Universities')),
  InterviewNew: React.lazy(() => import('../pages/Interview/Interview')),
  SingleInterviewNew: React.lazy(() => import('../pages/Interview/SingleUniversity/SingleInterview')),
  SingleUniversity: React.lazy(() => import('../pages/Universities/SingleUniversity/SingleUniversity')),
  Developers: React.lazy(() => import('../pages/Developers/Developers')),
  SingleDev: React.lazy(() => import('../pages/Developers/SingleDev/SingleDev')),
  Users: React.lazy(() => import('../pages/Users/Users')),
  SingleUser: React.lazy(() => import('../pages/Users/SingleUser/SingleUser')),
  VotingPeople: React.lazy(() => import('../pages/Voting/VotingPeople')),
  VotingPerson: React.lazy(() => import('../pages/Voting/VotingPerson/VotingPerson')),
  Nodes: React.lazy(() => import('../pages/Nodes/Nodes')),
  Layout: React.lazy(() => import('../components/Layout/Layout')),
  NoMatch: React.lazy(() => import('../pages/404/NoMatch')),
  Dashboard: React.lazy(() => import('../pages/Dashboard/Dashboard')),
  OpenedSurveys: React.lazy(() => import('../pages/OpenedSurveys/OpenedSurveys')),
  Banners: React.lazy(() => import('../pages/Banners/Banners')),
  PastSurveys: React.lazy(() => import('../pages/PastSurveys/PastSurveys')),
  PastSurveysItem: React.lazy(() => import('../pages/PastSurveys/PastSurveysItem/PastSurveysItem')),
};
