const routeNames = {
  root: '/',
  restorePWD: '/restore',
  newPWD: '/new_password/:id',
  accounts: '/accounts',
  users: '/accounts/users',
  user: '/accounts/users/:user',
  nodes: '/nodes',
  dashboard: '/dashboard',
};
export default routeNames;
