import React, { Component } from 'react';
// import QuestionResultBar from '../../components/QuestionResultBar/QuestionResultBar';
// import QuestionResultDoughnut from '../../components/QuestionResultDoughnut/QuestionResultDoughnut';
import Select from '../../components/Select/Select';
// import CommonData from '../../components/CommonData/CommonData';
// import { COMMON_DATA_TYPE } from '../../utils/constats';
import './Dashboard.scss';

class Index extends Component {
  state = {
    selectOptions: [
      'Todos',
      'Votantes legales',
      'Juventud',
    ],
    selectCityOptions: [
      'All',
      'Barranquilla',
      'Bogota',
      'Bucaramanga',
      'Cali',
      'Cartagena',
      'Medellin',
    ],
    selectValue: 'Todos',
    selectCityValue: 'All',
    cities: {
      id: 1,
      title: 'CITIES',
      answers: [
        { id: 11, title: 'Barranquilla', value: 450 },
        { id: 12, title: 'Bogota', value: 1800 },
        { id: 13, title: 'Bucaramanga', value: 225 },
        { id: 14, title: 'Cali', value: 600 },
        { id: 15, title: 'Cartagena', value: 450 },
        { id: 16, title: 'Medellin', value: 900 },
      ],
    },
    ages: {
      answers: [
        { id: 11, title: '18 - 29 years', value: 50 },
        { id: 12, title: '30 - 39 years', value: 30 },
        { id: 13, title: '40 - 49 years', value: 120 },
        { id: 14, title: '50 - 59 years', value: 50 },
        { id: 15, title: '60+', value: 50 },
      ],
    },
    genders: [
      {
        answers: [
          { id: 11, title: 'Homber', value: 120 },
          { id: 12, title: 'Mujer', value: 50 },
          { id: 13, title: 'Otro', value: 30 },
        ],
      },
    ],
  };

  handelOnChangeSelect = (value) => {
    this.setState({
      selectValue: value,
    });
  };

  handelOnCityChangeSelect = (value) => {
    this.setState({
      selectCityValue: value,
    });
  };

  render() {
    const {
//      cities, ages, genders,
      selectOptions, selectValue, selectCityOptions, selectCityValue,
    } = this.state;
    return (
      <div className="content dashboard">
        <div className="page-title">
          <div className="name-box">
            <span>DashBoard</span>
          </div>
          <div className="section__right">
            <div className="page-title_input-box">
              <label>City</label>
              <Select
                onChange={this.handelOnCityChangeSelect}
                value={selectCityValue}
                options={selectCityOptions}
              />
            </div>
            <div className="page-title_input-box">
              <label>Age</label>
              <Select
                onChange={this.handelOnChangeSelect}
                value={selectValue}
                options={selectOptions}
              />
            </div>
          </div>
        </div>
        <div className="top-content">
          {/*<CommonData*/}
          {/*  surveys={213}*/}
          {/*  answers={1023}*/}
          {/*  users="2 000 000"*/}
          {/*  age={35}*/}
          {/*  time="00:23:17"*/}
          {/*  type={COMMON_DATA_TYPE.DASHBOARD}*/}
          {/*/>*/}
          {/*<div className="cities-data content-item">*/}
          {/*  <QuestionResultBar*/}
          {/*    questions={cities}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
        <div className="bottom-content">
          <div className="content-item age-data">
            {/*<QuestionResultDoughnut answers={ages.answers} title="AGE" doughnut />*/}
          </div>
          <div className="content-item gender-data">
            {/*<QuestionResultDoughnut answers={genders[0].answers} title="GENDER" />*/}
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
