import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Select.scss';
import Icon from '../Icon/Icon';

class Select extends Component {
  state = {
    isOpened: false,
    chosen: false,
  };

  handleDropdown = () => {
    const { isOpened } = this.state;
    const { disabled } = this.props;
    if (!disabled) {
      this.setState({ isOpened: !isOpened });
    }
  };

  handleOption = (e) => {
    const { onChange } = this.props;
    this.setState({ isOpened: false, chosen: true });
    onChange(e.target.getAttribute('data-value'));
  };

  handleClose = () => {
    this.setState({ isOpened: false });
  };

  renderDropdown = (options) => {
    const { isOpened } = this.state;

    if (isOpened) {
      return (
        <div className="select-dropdown">
          {
            options.map((option) => {
              return (
                <span className={classNames('select-option')} data-value={option} onClick={this.handleOption} key={option}>{option}</span>
              );
            })
          }
        </div>
      );
    }

    return null;
  };

  render() {
    const { options, value } = this.props;
    const { isOpened, chosen } = this.state;

    const selectClassname = classNames('select-wrapper', {
      'select-active': isOpened,
      'select-chosen': chosen,
    });
    const iconClassname = classNames('icon-down', {
      'icon-active': isOpened,
    });

    return (
      <button type="button" className={selectClassname} onClick={this.handleDropdown} tabIndex={0} onBlur={this.handleClose}>
        <div className={classNames('selected-item')}
        >{value}
        </div>
        {this.renderDropdown(options)}
        <Icon icon="grey_down" className={iconClassname} />
      </button>
    );
  }
}

Select.defaultProps = {
  disabled: false,
  valueClassName: false,
};

Select.propTypes = {
  value: PropTypes.any.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  valueClassName: PropTypes.bool,
};

export default Select;
