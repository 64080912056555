import React from 'react';
import { Route } from 'react-router';
import SideBar from '../SideBar/SideBar';
import Dashboard from '../../page/Dashboard';
import routeNames from '../../../../../utils/routeNames';
import { loadable } from '../../../../../utils/loadable';

const Index = () => {
  return (
    <div className="main">
      <SideBar />
      {/*<Route exact path={routeNames.users} component={loadable.Users} />*/}
      {/*<Route exact path={routeNames.user} component={loadable.SingleUser} />*/}
      {/*<Route exact path={routeNames.nodes} component={loadable.Nodes} />*/}
      <Route exact path='/bebe' component={Dashboard} />
    </div>
  );
};

export default Index;
