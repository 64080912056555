import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';

import './Input.scss';

const INPUT_TYPES = {
  text: 'text',
  email: 'email',
  tel: 'tel',
  password: 'password',
  url: 'url',
};

class Input extends React.PureComponent {
  state = {
    focus: false,
    passwordType: INPUT_TYPES.password,
  };

  handleFocus = () => {
    const { activeInput } = this.props;
    this.setState({ focus: true });
    activeInput && activeInput(true);
  };

  handleBlur = () => {
    const { activeInput } = this.props;
    this.setState({ focus: false });
    activeInput && activeInput(false);
  };

  handleChange = (e) => {
    const { onChange } = this.props;
    const { value, name } = e.target;
    onChange(value, name);
  };

  handleClearInput = () => {
    const { onChange, name } = this.props;
    onChange('', name);
  };

  handlePasswordDisplay = () => {
    const { passwordType } = this.state;
    const type = passwordType !== INPUT_TYPES.password ? INPUT_TYPES.password : INPUT_TYPES.text;

    this.setState({ passwordType: type });
  };

  renderError = (type) => {
    const { isValid, value, repeatPassword } = this.props;

    if (!isValid && type === INPUT_TYPES.password && repeatPassword) {
      return <span className="inputHint error-hint">Different Passwords</span>;
    }

    if (!isValid && value === '') {
      return <span className="inputHint error-hint">Required</span>;
    }

    return null;
  };

  renderLabel = (label) => {
    if (label !== '') {
      return (
        <span className="label">{label}</span>
      );
    }

    return null;
  };

  renderInput = (type, value) => {
    const { isValid, name, placeholder } = this.props;
    const { focus, passwordType } = this.state;
    const inputClassName = classNames('input', { focus, 'error-input': !isValid });
    const commonProps = {
      name,
      value,
      onChange: this.handleChange,
      onFocus: this.handleFocus,
      onBlur: this.handleBlur,
    };

    if (type === INPUT_TYPES.password) {
      return (
        <Fragment>
          <input
            {...commonProps}
            type={passwordType}
            className={inputClassName}
          />
          {
            value === ''
              ? <span className="placeholder">{placeholder}</span>
              : null
          }
          <Icon
            icon="eye"
            className={classNames('inputCrossIcon',
              {
                'focus-icon': passwordType === INPUT_TYPES.text,
                'error-icon': !isValid,
                invisible: value === '',
              })}
            onClick={this.handlePasswordDisplay}
            button
            tabIndex={-1}
          />
        </Fragment>
      );
    }

    return (
      <Fragment>
        <input
          {...commonProps}
          type={type === INPUT_TYPES.email ? INPUT_TYPES.text : type}
          className={inputClassName}
        />
        {
          value === ''
            ? <span className="placeholder">{placeholder}</span>
            : null
        }
        <Icon
          icon="cross"
          className={classNames('inputCrossIcon', {
            'focus-icon': focus,
            'error-icon': !isValid,
            invisible: value === '',
          })}
          onClick={this.handleClearInput}
          button
          tabIndex={-1}
        />
      </Fragment>
    );
  };

  render() {
    const { type, value, label } = this.props;

    return (
      <div className="outerInputWrapper">
        {this.renderLabel(label)}
        <div className="innerInputWrapper">
          {this.renderInput(type, value)}
          {this.renderError(type)}
        </div>
      </div>
    );
  }
}

Input.defaultProps = {
  label: '',
  isValid: true,
  name: null,
  onChange: () => {},
  placeholder: '',
};

Input.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  isValid: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default Input;
