/* eslint-disable no-useless-escape */
export const validateRequired = (value) => {
  return value !== '';
};

export const validateCyrillic = (value) => {
  const re = /[а-яА-ЯЁё]/;
  return !re.test(value);
};

export const validateTextLessHundred = (value) => {
  return value.length <= 100;
};

export const validateNoNumbers = (value) => {
  return !value.match(/[0-9]/g);
};

export const validateEmail = (email) => {

  const re = /[a-z0-9\._%+!$&*=^|~#'`?{}\/\-]+@([a-z0-9\-]{2,16}\.[a-z]{2,8}$)/;
  return re.test(email) && validateCyrillic(email) && email.indexOf(' ') === -1 && email.split('@')[0].length <= 64;
};

export const validateEmailServices = (email) => {
  const emailServices = [
    /.+@(gmail)\.com$/,
    /.+@(zoho)\.com$/,
    /.+@(outlook)\.com$/,
    /.+@(mail)\.com$/,
    /.+@(yahoo)\.com$/,
    /.+@(gmx)\.com$/,
    /.+@(yandex)\.ru$/,
    /.+@(icloud)\.com$/,
  ];

  const emailService = emailServices.some(value => value.test(email));

  return !emailService && validateEmail(email);
};

export const validateDomain = (domain) => {
  const re = /^HTTP|HTTP|http(s)?:\/\/|(www\.)?[A-Za-z0-9]+([\-\.][A-Za-z0-9]+)*\.[A-Za-z]{2,40}(:[0-9]{1,40})?(\/.*)?$/;

  return re.test(domain);
};

export const validateTel = (value) => {
  return /^\d+$/.test(value) && value.length <= 15;
};

export const validatePassword = (password) => {
  const re = /^\S*$/;
  const minPasswordLength = 8;

  return re.test(password) && password.length >= minPasswordLength && validateRequired(password);
};

export const validateFileSize = (file) => {
  if (file) {
    const { size } = file;
    const maxSize = 2000000;

    return size <= maxSize;
  }

  return false;
};

export const validateFileType = (file) => {
  if (file) {
    const { type } = file;
    const validTypes = [
      'image/jpg',
      'image/jpeg',
      'image/png',
      'application/pdf',
    ];

    return validTypes.some(validType => validType === type);
  }

  return false;
};

export const validateFiles = (value) => {
  // return [...value].every(file => validateFileSize(file)
  //   && validateFileType(file)) && value.length !== 0;
  return value.length !== 0;
};
