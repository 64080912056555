import { configureStore } from '@reduxjs/toolkit';

import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import logger from 'redux-logger'

import userReducer from './user';
import modalReducer from './modal';
import portfolioReducer from './portfolio';
import postReducer from './post';

const reducers = combineReducers({
  'user': userReducer,
  'portfolio': portfolioReducer,
  'blog': postReducer,
  'modal': modalReducer
});

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, reducers);


const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk, logger]
});

export default store;