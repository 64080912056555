import axios from 'axios';

export const HTTP = axios.create({
  'baseURL': '',
  'timeout': 10000,
  'headers': {
    'Accept': 'application/json, text/plain, /',
    'Content-Type': 'application/json'
  }
});
