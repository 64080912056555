import { createSlice } from '@reduxjs/toolkit';

export const portfolioSlice = createSlice({
  'name': 'portfolio',
  'initialState': {
    projects: []
  },
  'reducers': {
    initProjects: (state, action) => {
      state.projects = action.payload.projects;
    }
  }
});

export const { initProjects } = portfolioSlice.actions;

export default portfolioSlice.reducer;
