
// ###### portfolio  #########
import portfolio1 from '../resources/img/portfolio/ct9uh-min.png';
import portfolio2 from '../resources/img/portfolio/i94ol-min.png';
import portfolio3 from '../resources/img/portfolio/ozqfe-min.png';
import portfolio4 from '../resources/img/portfolio/rx5c4-min.png';
import portfolio5 from '../resources/img/portfolio/uyafi-min.png';
import portfolio6 from '../resources/img/portfolio/v3ex7-min.png';

import post1 from '../resources/img/blogs/SanFran-min.png';
import post2 from '../resources/img/blogs/prague-min.png';
import post3 from '../resources/img/blogs/venice-min.png';

export const portfolio = [
  {
    id: 20001,
    title: 'AlphaFit',
    context: 'bebebebe',
    img: portfolio1,
  },
  {
    id: 20002,
    title: 'Exchange platform',
    context: 'Accumulate crypto currency on a monthly basis, without having to deal with the payment or order process.',
    img: portfolio2,
  },
  {
    id: 20003,
    title: 'The Unbounded Network™',
    context: 'Intelligent Data and Digital-Asset Exchange',
    img: portfolio3,
  },
  {
    id: 20004,
    title: 'Election Platform',
    context: '',
    img: portfolio4,
  },
  {
    id: 20005,
    title: 'Health insurance',
    context: 'For nearly two decades, ConnectureDRX’s vast network of health plan data has brought carriers, FMOs and brokers together to simplify selling health plans in order to help millions of consumers find and enroll in their best fit Medicare plans every year.',
    img: portfolio5,
  },
  {
    id: 20006,
    title: 'AlphaFit',
    context: '',
    img: portfolio6,
  }
]

// ###### Posts  #########


export const posts = [
  {
    id: 10001,
    title: 'Amazing adventure in San Francisco 2016',
    header: 'San Francisco, officially the City and County of San Francisco, is the\n' +
      '                              cultural, commercial, and financial center of Northern California.',
    context: '',
    img: post1,
  },
  {
    id: 10002,
    title: 'Unforgettable Prague 2019',
    header: 'Prague, capital city of the Czech Republic, is bisected by the Vltava River.',
    context: '',
    img: post2,
  },
  {
    id: 10003,
    title: 'Enchanting Venice 2017',
    header: 'Venice, the capital of northern Italy’s Veneto region, is built on more than\n' +
      '                    100 small islands in a lagoon in the Adriatic Sea.',
    context: '',
    img: post3,
  }
]