import React from 'react';

export const loadable = {
  Login: React.lazy(() => import('../pages/Login')),
  //RestorePWD: React.lazy(() => import('../pages/restorePwd/RestorePWD')),
  //NewPWD: React.lazy(() => import('../pages/newPwd/NewPWD')),
  //Users: React.lazy(() => import('../pages/Users/Users')),
  //SingleUser: React.lazy(() => import('../pages/Users/SingleUser/SingleUser')),
  //Models: React.lazy(() => import('../pages/Nodes/Nodes')),
  Layout: React.lazy(() => import('../modules/admin/v1/components/Layout')),
  //NoMatch: React.lazy(() => import('../pages/404/NoMatch')),
  Dashboard: React.lazy(() => import('../modules/admin/v1/page/Dashboard')),
};
