import React, {useEffect} from 'react';
import {closeModal} from "../../store/actions";
import {useDispatch, useSelector} from 'react-redux';
import './Modal.scss';
import close from "../../resources/img/close.svg";
import { selectModal } from "../../store/selectors";

export const Modal = () => {
  const dispatch = useDispatch();
  const { data } = useSelector(selectModal);
  console.log('Modal data  --: ', data);

  useEffect(() => {
    document.body.classList.add('modal-open');
  }, []);

  const onCloseModal = () => {
      document.body.classList.remove('modal-open');
      dispatch(closeModal())
  }

  return (
    <div className="modal-main-section">
      <div className="modal-content">
        <div className="modal-close">
          <button
            type="button"
            className="close-btn"
            onClick={onCloseModal}
          >
            <img src={close} alt=""/>
          </button>
        </div>
        <div className="modal-section-container">
          <div className="section-left">
            <img src={data.img} alt=">"/>
          </div>
          <div className="section-right">
            <h4>{data.title}</h4>
            <p>
              {data.context}
            </p>
          </div>
        </div>

      </div>
    </div>
  );
};
