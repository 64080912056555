import { HTTP } from '../services/http';
import { login, logout } from './user';
import { open, close } from './modal';
import { initProjects } from './portfolio';
import { initPosts } from './post';
import { portfolio, posts } from '../utils/mocki';
import api from '../utils/apiEndpoints';
import routeNames from '../utils/routeNames';
import history from '../utils/history';

// #### Init data ####
export const initPortfolioAction = () => async dispatch => {
  // const res = await HTTP.get('url');

  dispatch(initProjects({
    projects: portfolio
  }));
};

export const initPostsAction = () => async dispatch => {
  // const res = await HTTP.get('url');

  dispatch(initPosts({
    posts
  }));
};
// ####  ####

export const loginAction = (params) => async dispatch => {
  const { data: { token, user } } = await HTTP.post(api.login, params);

  dispatch(login({
    token,
    user
  }));

  history.push(routeNames.accounts);
};

export const logoutAction = () => dispatch => dispatch(logout());

export const openModal = (data) => dispatch => dispatch(open(data));
export const closeModal = () => dispatch => dispatch(close())
