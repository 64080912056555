import { createSlice } from '@reduxjs/toolkit';

export const postSlice = createSlice({
  'name': 'post',
  'initialState': {
    posts: []
  },
  'reducers': {
    initPosts: (state, action) => {
      state.posts = action.payload.posts;
    }
  }
});

export const { initPosts } = postSlice.actions;

export default postSlice.reducer;
