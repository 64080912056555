import React, {useEffect, useState} from 'react';
import './HomeSection.scss';

const jobs = ['DEVELOPER', 'TRAVELER'];
const getJob = (jobs) => jobs[Math.floor(Math.random() * jobs.length)]

export const HomeSection = () => {

    const [headerContent, setHeaderContent] = useState('');

    useEffect( () => {
        const job = getJob(jobs);

        for (let i = 1; i <= job.length; i++) {
            setTimeout(() => setHeaderContent(job.substring(0, i)),  i * 300);
        }

        setInterval( () => {
            const job = getJob(jobs);

            for (let i = 1; i <= job.length; i++) {
                setTimeout(() => setHeaderContent(job.substring(0, i)),  i * 300);
            }
        }, 6000)
    }, [])

  return (
    <>
        <div className="header-content">
            <h1>I'm <span className="typed">{headerContent}.</span><span className="typed-cursor">|</span></h1>
            <p>FullStack developer, traveler</p>
        </div>
    </>
  );
};
