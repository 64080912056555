import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { selectUser } from '../store/selectors';

export function PrivateRoute ({ component: Component, ...rest }) {
  const user = useSelector(selectUser);
  console.log('user  --: ', user);
  return (
    <Route {...rest} render={(props) => (
      user.isAuthenticated && user.token
        ? <Component {...props} />
        : <Redirect to='/login' />
    )} />
  );
}
