import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  'name': 'user',
  'initialState': {
    token: '',
    user: {},
    isAuthenticated: false,
  },
  'reducers': {
    login: (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.token = '';
      state.user = {};
      state.isAuthenticated = false;
    },
  }
});

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;
