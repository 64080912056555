import React, {Suspense} from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.scss';
//import { Footer } from './components/Footer';
import { PrivateRoute } from './helpers/PrivateRoute';
import { Modal } from './modules/modal';
import { useDispatch, useSelector } from 'react-redux';
import { selectModal } from './store/selectors';
import { MainSection } from "./pages/Home/MainSection";
import Login from './pages/Login';
import { Blog } from './modules/blog/BlogFolio';
import { NotFound } from './pages/NotFound';
import Layout from './modules/admin/v1/components/Layout';
import { initPortfolioAction, initPostsAction } from "./store/actions";
import routeNames from "./utils/routeNames";
import { loadable as loadAdmin } from "./modules/admin/v2/src/utils/loadable";
import { loadable as loadCV } from "./utils/loadable";
import LoadingSpinner from "./modules/admin/v2/src/components/LoadingSpinner/LoadingSpinner";

function App() {
    const { isOpen } = useSelector(selectModal);
    const dispatch = useDispatch();
    dispatch(initPortfolioAction())
    dispatch(initPostsAction())

    return (
      <Suspense fallback={<LoadingSpinner />}>
        <div className="App">
            {console.log('isOpen  --:', isOpen)}
            { isOpen && <Modal /> }
            <Switch>
                <Route exact path="/" component={ MainSection }/>
                {/*<Route exact path={routeNames.dashboard} component={Layout} />*/}
                {/*<PrivateRoute exact path={routeNames.accounts} component={ loadAdmin.Layout } />*/}
                <PrivateRoute path={routeNames.accounts} component={ loadAdmin.Layout } />
                <Route exact path="/login" component={ loadCV.Login }/>
                <Route exact path="/blogs/:id" component={ Blog }/>
                <Route path="*" component={ NotFound }/>
            </Switch>
            {/*<Footer/>*/}
        </div>
      </Suspense>
    );
}

export default App;
