import React, { Component } from 'react';
import classNames from 'classnames';
import { Link, NavLink } from 'react-router-dom';
import Icon from '../Icon/Icon';
import './SideBar.scss';
import routeNames from '../../../../../utils/routeNames';
import logo from '../../resources/img/logo_bean.png';

class SideBar extends Component {
  state = {
    isSidebar: true,
    isAccountDropdown: false,
  };

  toggleAside = () => {
    const { isSidebar } = this.state;
    this.setState({
      isSidebar: !isSidebar,
    });
  };

  toggleAccountDropdown = type => {
    this.setState({
      [`is${type}`]: !this.state[`is${type}`],
    });
  };

  render() {
    const {
      isSidebar,
      isProfile,
    } = this.state;
    return (
      <div className={classNames('sidebar',
        { 'sidebar-open': isSidebar })}
      >
        <div className="sidebar_header" onClick={this.toggleAside}>
          <div className="header-logo">
            <img src={logo} className="logo-svg" alt="" />
            <span className="logo-text">CV</span>
          </div>
          <div className="sidebar_arrow">
            <Icon icon="grey_down" />
          </div>
        </div>

        {/* Блок для супер админа который вожет редактировать, какая страница будет home (estilo или cv), что предоставлять юзеру */}
        {/* Super admin - Может редактировать и менять состояние сайта*/}
        {/* Admin - может только заполнять и использовать INW5000 и свой профиль */}
        {/* User - имеет только обзие данные и свою в профиле, нужен что быть для статистики admin и super admin */}

        <div className={classNames('account__links-box')}>
          <div className="sidebar_main-link" onClick={() => this.toggleAccountDropdown('Profile')}>
            <Icon className="sidebar_main-link_icon" icon="account" />
            <span className="sidebar_main-link_text">Profile</span>
          </div>
          {isProfile && (
          <nav className="type-account">
            <NavLink to={routeNames.universities} className="nav-link" activeClassName="account_link-active "><span>Account</span></NavLink>
            <NavLink to={routeNames.developers} className="nav-link" activeClassName="account_link-active "><span>Settings</span></NavLink>
            <NavLink to={routeNames.users} className="nav-link" activeClassName="account_link-active "><span>Users</span></NavLink>
          </nav>
          )}
        </div>

        <Link to={routeNames.root} className="sidebar_logout-box">
          <Icon icon="ic_logout" className="icon" />
          <span className="logout">Log Out</span>
        </Link>
      </div>
    );
  }
}

export default SideBar;
