/* eslint camelcase: "off" */
import { ReactComponent as cross } from './icons/cross.svg';
import { ReactComponent as eye } from './icons/eye.svg';
import { ReactComponent as logo } from './icons/logo.svg';
import { ReactComponent as account } from './icons/account.svg';
import { ReactComponent as grey_down } from './icons/grey-down.svg';
import { ReactComponent as ic_logout } from './icons/ic-logout.svg';
import { ReactComponent as ic_nodes } from './icons/ic-nodes.svg';
import { ReactComponent as search } from './icons/search.svg';
import { ReactComponent as ic_calendar } from './icons/ic-calendar.svg';
import { ReactComponent as trash } from './icons/trash.svg';
import { ReactComponent as no_apps } from './icons/no_apps.svg';
import { ReactComponent as tel } from './icons/tel.svg';
import { ReactComponent as dashboard } from './icons/dashboard.svg';
import { ReactComponent as openedSurveys } from './icons/openedSurveys.svg';
import { ReactComponent as pastSurveys } from './icons/pastSurveys.svg';
import { ReactComponent as gallery } from './icons/gallery.svg';

const iconList = {
  account,
  cross,
  grey_down,
  eye,
  logo,
  ic_logout,
  ic_nodes,
  search,
  ic_calendar,
  trash,
  no_apps,
  tel,
  dashboard,
  gallery,
  openedSurveys,
  pastSurveys
};

export default iconList;
