import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
//import { BrowserRouter as Router } from 'react-router-dom';
import { Router } from "react-router-dom";

import './index.scss';
import App from './App';
import store from './store';
import history from './utils/history';
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'

let persistor = persistStore(store);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
                <App />
            </Router>
          </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

/*
 * If you want your store to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
