import React from 'react';
import './JournalSection.scss';
import { useSelector } from "react-redux";
import { selectPosts } from "../../../store/selectors";
import {Link} from "react-router-dom";

export const JournalSection = () => {

  const posts = useSelector(selectPosts);

  console.log('posts  --: ', posts);

  return (
    <div id="journal" className="journal-blog text-left paddsection">
      <div className="container">
        <div className="section-title text-center">
          <h2>Blog</h2>
        </div>
      </div>

      <div className="container">
        <div className="journal-block">
          <div className="row">

            {posts.map(post => <div className="col-lg-4 col-md-6" key={post.id}>
                <Link to={`/blogs/${post.id}`} params={{ value: "hello" }}>
                    <div className="journal-info">

                        <a href="blog-single.html"><img src={post.img} className="img-responsive"
                                                        alt="img"/></a>

                        <div className="journal-txt">

                            <h4>{post.title}</h4>
                            <p className="separator">{post.header}
                            </p>

                        </div>

                    </div>
                </Link>

            </div>)}

          </div>
        </div>
      </div>

    </div>
  );
};
