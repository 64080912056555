import React from 'react';
import './NotFound.scss';
import { loginAction } from "../../store/actions";
import { useDispatch } from 'react-redux';

export const NotFound = () => {
  const dispatch = useDispatch();

  return (
    <div className="not-found-main-section">

      <div className="not-found-content">
        <h1>404</h1>
        <h5>Что то пошло не так!</h5>

        <div className="social-links">
          <a href="https://www.instagram.com/alphafit.pro/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram fa-lg"/>
          </a>
          <a href="https://web.telegram.org/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-telegram fa-lg"/>
          </a>
          <a href="mailto:alphafit.pro2020@gmail.com" target="_blank" rel="noopener noreferrer">
            <i className="far fa-envelope fa-lg"/>
          </a>
        </div>

        <button
          type="button"
          className="grey-btn"
          onClick={() => dispatch(loginAction('test name'))}
        >
          BTN
        </button>
      </div>

    </div>
  );
};
