import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './PortfolioSection.scss';
import { selectProjects } from "../../../store/selectors";
import { openModal } from "../../../store/actions";

export const PortfolioSection = () => {

  const dispatch = useDispatch();
  const projects = useSelector(selectProjects);

    console.log('projects  --: ', projects);
    return (
    <div className="portfolio-content text-center paddsection">
        <div className="container">
            <div className="section-title text-center">
                <h2>My Portfolio</h2>
            </div>
        </div>

        <div className="container">
            <div className="row">
                <div className="col-md-12">

                    <div className="portfolio-list">

                        <ul className="nav list-unstyled" id="portfolio-flters">
                            <li className="filter filter-active" data-filter=".all">all</li>
                            <li className="filter" data-filter=".branding">Landing page</li>
                            <li className="filter" data-filter=".mockups">Corporate website</li>
                            <li className="filter" data-filter=".uikits">Internet shop</li>
                            <li className="filter" data-filter=".webdesign">Portal</li>
                        </ul>

                    </div>

                    <div className="portfolio-container">
                        {projects.map(project =>
                            <div key={project.id} className="col-lg-4 col-md-6 portfolio-thumbnail">
                                <div className="popup-img" href={project.img} onClick={() => dispatch(openModal(project))}>
                                    <img src={project.img} alt={project.img}/>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};
