import React from 'react';
import './Footer.scss';
import {Link} from "react-router-dom";

export const Footer = () => {
  return (
    <footer className={'app-footer'}>
      <div className="social-links">
        <a href="https://www.instagram.com/dima.a.t/" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram fa-lg"/>
        </a>
        <a href="https://t.me/dimasikT" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-telegram fa-lg"/>
        </a>
        <a href="https://www.facebook.com/dimatimk/" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-facebook fa-lg"/>
        </a>
        <a href="skype:demionfox2009" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-skype fa-lg"/>
        </a>
        <a href="https://www.linkedin.com/in/dmitry-tymoshyk-04987775/" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-linkedin fa-lg"/>
        </a>
        <Link to="/login" className="singIn-link">
          <i className="fab fa-grav fa-lg"></i>
        </Link>
      </div>
    </footer>
  );
};
