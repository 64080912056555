import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { validateEmail, validatePassword } from '../../utils/validators';
import { loginAction } from '../../store/actions';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import './Login.scss';
import logo from '../../resources/img/logo_bean.png';
import { Link } from "react-router-dom";

export const Login = () => {

  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(undefined);
  const [password, setPassword] = useState('');
  const [isPassValid, setIsPasswordValid] = useState(undefined);

  const dispatch = useDispatch();

  const handleEmail = (valueEmail) => {
    const value = valueEmail.replace(' ', '');
    setEmail(value);
    setIsEmailValid(validateEmail(value));
  };

  const handlePass = (valuePass) => {
    const value = valuePass.replace(' ', '');
    setPassword(value);
    setIsPasswordValid(validatePassword(value));
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();

    dispatch(loginAction({
      email,
      password,
    }));
  };

  return (
      <div className="auth-wrapper">
        <h3 className="auth-title">Login</h3>
        <form className="auth-form">
          <Link to="/" >
            <img src={logo} alt="" />
          </Link>
          <div className="login-form">
            <Input
                type="email"
                label="Email"
                value={email}
                onChange={handleEmail}
                isValid={isEmailValid}
                autoFocus
            />
            <Input
                type="password"
                label="Password"
                value={password}
                onChange={handlePass}
                isValid={isPassValid}
            />
            <div className="login-bottom">
              <Button
                  type="submit"
                  size="xl"
                  color="green"
                  text="Login"
                  disabled={!(isEmailValid && isPassValid)}
                  onClick={handleSubmitForm}
              />
              {/*<div className="login-hint">*/}
              {/*  <span>Did you forget your password?</span>*/}
              {/*  <Link to={routeNames.restorePWD}>Restore password</Link>*/}
              {/*</div>*/}
            </div>
          </div>
        </form>
      </div>
  );
}

export default Login;
