import React, {useRef, useState, useEffect} from 'react';
import '../../../components/Header/Header.scss';
import './MainSection.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import location from "../../../resources/img/maps-and-flags.svg";
import menuIcon from "../../../resources/img/menu_icon.svg";
import { HomeSection } from '../HomeSection';
import { AboutSection } from '../AboutSection';
import { PortfolioSection } from '../PortfolioSection';
import { JournalSection } from '../JournalSection';
import { ContactSection } from '../ContactSection';
import {Footer} from "../../../components/Footer";
import { useDispatch } from "react-redux";
import { logoutAction } from '../../../store/actions';

function socialLinks() {
  return <div className="social-links-mobile">
    <a href="https://www.instagram.com/dima.a.t/" target="_blank" rel="noopener noreferrer">
      <i className="fab fa-instagram fa-lg"/>
    </a>
    <a href="https://t.me/dimasikT" target="_blank" rel="noopener noreferrer">
      <i className="fab fa-telegram fa-lg"/>
    </a>
    <a href="https://www.facebook.com/dimatimk/" target="_blank" rel="noopener noreferrer">
      <i className="fab fa-facebook fa-lg"/>
    </a>
    <a href="skype:demionfox2009" target="_blank" rel="noopener noreferrer">
      <i className="fab fa-skype fa-lg"/>
    </a>
    <a href="https://www.linkedin.com/in/dmitry-tymoshyk-04987775/" target="_blank" rel="noopener noreferrer">
      <i className="fab fa-linkedin fa-lg"/>
    </a>
    {/*<a href="mailto:alphafit.pro2020@gmail.com" target="_blank" rel="noopener noreferrer">*/}
    {/*  <i className="far fa-envelope fa-lg"/>*/}
    {/*</a>*/}
  </div>;
}

export const MainSection = () => {
  //const dispatch = useDispatch();
  const homeSectionRef = useRef();
  const aboutSectionRef = useRef();
  const portfolioSectionRef = useRef();
  const journalSectionRef = useRef();
  const contactSectionRef = useRef();

  const [isMobileNavigation, showMobileNavigation] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('useEffect  --: ');
    dispatch(logoutAction());
  }, []);

  const switchMobileMenu = () => {
    showMobileNavigation(!isMobileNavigation);
  };

  const scrollToRef = (resultsRef = 0) => {
    window.scrollTo({
      behavior: "smooth",
      top: resultsRef === 0 ? 0: resultsRef.current.offsetTop - 80
    });

    showMobileNavigation(false);
  }

  return (
      <React.Fragment>
        <div className="home-page">
          <header className={`App-header${isMobileNavigation ? ' fixed' : ''}`}>
            <div className="mobile-location">
              <div className="mobile-location_address">
                <img src={location} alt=""/>
                <span>ул. Победы 74</span>
              </div>
              <a className="mobile-location_phone" href="tel:+38(099)107-12-12">+38(099)107-12-12</a>
            </div>
            <a href="#/"  onClick={() => scrollToRef(homeSectionRef)} className="logo">
              <p>Dmytro<br/><br/>&ensp;&ensp;&ensp;Tymoshyk</p>
            </a>
            <div className={`navigation${isMobileNavigation ? ' mobile-version' : ''}`}>
              <a href="#/" activeClassName="active-link" onClick={() => scrollToRef(homeSectionRef)}>Home</a>
              <a href="#/" activeClassName="active-link" onClick={() => scrollToRef(aboutSectionRef)}>About</a>
              <a href="#/" activeClassName="active-link"
                 onClick={() => scrollToRef(portfolioSectionRef)}>Portfolio</a>
              <a href="#/" activeClassName="active-link"
                 onClick={() => scrollToRef(journalSectionRef)}>Blog</a>
              <a href="#/" activeClassName="active-link"
                 onClick={() => scrollToRef(contactSectionRef)}>Contact</a>
              {socialLinks()}
            </div>
            <div className="social-links">
              <a href="https://www.instagram.com/dima.a.t/" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram fa-lg"/>
              </a>
              <a href="https://t.me/dimasikT" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-telegram fa-lg"/>
              </a>
              <a href="https://www.facebook.com/dimatimk/" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook fa-lg"/>
              </a>
              <a href="skype:demionfox2009" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-skype fa-lg"/>
              </a>
              <a href="https://www.linkedin.com/in/dmitry-tymoshyk-04987775/" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-linkedin fa-lg"/>
              </a>

            </div>
            <div
                className="mobile-navigation"
                onClick={switchMobileMenu}
            >
              <img src={menuIcon} alt="menu"/>
            </div>
          </header>
          <main className="main-section">

            <section className="first-section" ref={homeSectionRef}>
              <HomeSection />
            </section>

            <section className="about-section" ref={aboutSectionRef}>
              <AboutSection />
            </section>

            {/*<section className="career-section">*/}
            {/*  <CareerSection />*/}
            {/*</section>*/}

            <section className="portfolio-section" ref={portfolioSectionRef}>
              <PortfolioSection />
            </section>

            <section className="journal-section" ref={journalSectionRef}>
              <JournalSection />
            </section>

            <section className="contact-section" ref={contactSectionRef}>
              <ContactSection />
            </section>

          </main>
        </div>
        <Footer/>
      </React.Fragment>
  );
};
