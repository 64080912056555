import React from 'react';
import './AboutSection.scss';
import about from '../../../resources/img/about_pic.jpg';

export const AboutSection = () => {
  return (
      <div className="about-container">
          <div className="row justify-content-between">

              <div className="col-lg-4 ">
                  <div className="div-img-bg">
                      <div className="about-img">
                          <img src={about} className="img-responsive" alt="me"/>
                      </div>
                  </div>
              </div>

              <div className="col-lg-7">
                  <div className="about-descr">

                      <p className="p-heading">FullStack Developer</p>
                      <p className="separator">I'm a fullstack software engineer.
                          I have started my professional career as a java developer in 2013.
                          Since that time I've worked on several successful projects.
                          I have got experience in web applications design and development.
                          I have a responsible attitude to work, excellent communication skills, good analytical abilities.
                          I am a quick learner, ambitious and reliable employee.
                      </p>
                  </div>

              </div>
          </div>
      </div>
  );
};
