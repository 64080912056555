//const baseURL = 'http://109.87.63.252:5005';
//const baseURL = 'https://109.87.63.252:443';
//const baseURL = 'https://dtymosserver.space';
//const baseURL = 'http://localhost:5005';
const baseURL = 'https://dtymos-cv.space';

const apiEndpoints = {
  login: `${baseURL}/user/login`,
  reset: `${baseURL}/user/reset/link`,
  password: `${baseURL}/user/reset/password`,
  accounts: `${baseURL}/accounts`,
  account: id => `${baseURL}/admin/account/${id}`,
  user: `${baseURL}/admin/account/user`,
  developer: `${baseURL}/admin/account/developer`,
  university: `${baseURL}/admin/account/university`,
  questions: `${baseURL}/questions`,
  apiKey: `${baseURL}/admin/apikey`,
  pubKey: `${baseURL}/admin/pubKey`,
  blockIndex: `${baseURL}/admin/blockIndex`,
  nodes: `${baseURL}/admin/nodes`,
  userInfo: `${baseURL}/admin/account/info`,
  apps: `${baseURL}/admin/apps`,
  appStatus: `${baseURL}/admin/appStatus`,
  kycStatus: `${baseURL}/admin/kycStatus`,
};

export default apiEndpoints;
