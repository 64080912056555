import { createSlice } from '@reduxjs/toolkit';

export const modalSlice = createSlice({
  'name': 'modal',
  'initialState': {
    data: null,
    isOpen: false,
  },
  'reducers': {
    open: (state, action) => {
      state.data = action.payload;
      state.isOpen = true;
    },
    close: (state) => {
      state.data = null;
      state.isOpen = false;
    },
  }
});

export const { open, close } = modalSlice.actions;

export default modalSlice.reducer;
